import { LabelService } from 'src/app/services/label/label.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, Events, AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config/config.service';
import { FiltersService } from '../services/filters/filters.service';

@Component({
  selector: 'app-shop-filters',
  templateUrl: './shop-filters.page.html',
  styleUrls: ['./shop-filters.page.scss'],
})
export class ShopFiltersPage implements OnInit {
  categoryId: string;
  brandId: string;
  parentFilter = [];
  noCategories = false;
  noBrands = false;
  showLoader = true;
  filtersActive = {parent: false, price: false, discount: false, rating: false};

  discountFilter = [
    {
      filter: '10% and above',
      range: [10, 100],
      isChecked: false
    },
    {
      filter: '20% and above',
      range: [20, 100],
      isChecked: false
    },
    {
      filter: '30% and above',
      range: [30, 100],
      isChecked: false
    },
    {
      filter: '40% and above',
      range: [40, 100],
      isChecked: false
    },
    {
      filter: '50% and above',
      range: [50, 100],
      isChecked: false
    },
    {
      filter: '60% and above',
      range: [60, 100],
      isChecked: false
    },
  ];
  ratingFilter = [
    {
      range: [1, 5],
      isChecked: false
    },
    {
      range: [2, 5],
      isChecked: false
    },
    {
      range: [3, 5],
      isChecked: false
    },
    {
      range: [4, 5],
      isChecked: false
    }
  ];
  discountRange = [0, 100];
  ratingRange = [0, 5];
  priceRange = {lower: 1, upper: 2500};
  priceRangeMin = 1;
  priceRangeMax = 2500;
  priceRangeSteps = 100;
  currencyCode: string;
  appliedParentFilter: any;
  SHARED_LABELS: any = {};
  adminFilters: any;
  productRatings = false;
  constructor(private events: Events,
              private modalController: ModalController,
              private alertController: AlertController,
              private labelService: LabelService,
              private configService: ConfigService,
              private filtersService: FiltersService,) { }

  ngOnInit() {
    // console.log('categoryId', this.categoryId);
    // console.log('brandId', this.brandId);
    this.currencyCode = this.configService.environment.currencyCode;
    this.productRatings = this.configService.environment.productRatings;
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
  }

  ionViewWillEnter() {
    this.initializeSubscriptions();
    if (this.categoryId) {
      this.events.publish('brands:getAllBrandsForUser');
    }
    if (this.brandId) {
      this.events.publish('product:getAllCategoriesForShop');
    }
    if (this.priceRange.upper === 10000000000000) {
      this.priceRange.upper = this.priceRangeMax;
    }
    // if(!this.adminFilters.length) {
    //   this.events.publish('filters:getAllActiveFilters');
    // }
  }

  ionViewWillLeave() {
    this.removeSubscriptions();
  }

  async initializeSubscriptions() {
    this.events.subscribe('product:publishAllCategoriesForShop', (categories) => {
      if (this.brandId) { 
        categories.forEach(c => {
          this.parentFilter.push({id: c.id, name: c.name, isChecked: false});
        });
        if (this.appliedParentFilter && this.appliedParentFilter.hasOwnProperty('ids')) {
          this.parentFilter.forEach((p) => {
            if (this.appliedParentFilter.ids.includes(p.id)) {
              p.isChecked = true;
            }
          });
        }
        this.showLoader = false;
      }
    });
    this.events.subscribe('product:noCategoryAvailable', () => {
        this.showLoader = false;
        this.noCategories = true;
    });
    this.events.subscribe('brands:publishAllBrandsForUser', (brands) => {
      if(this.categoryId) {
        brands.forEach(b => {
          this.parentFilter.push({id: b.id, name: b.name, isChecked: false});
        });
        if (this.appliedParentFilter && this.appliedParentFilter.hasOwnProperty('ids')) {
          this.parentFilter.forEach((p) => {
            if (this.appliedParentFilter.ids.includes(p.id)) {
              p.isChecked = true;
            }
          });
        }
      }
    });
    this.events.subscribe('brands:noBrandAvailableForUser', () => {
      this.noBrands = true;
      this.showLoader = false;
    });

    if (!this.adminFilters.length) {
      this.adminFilters = [];
      const filters: any = await this.filtersService.getAllActiveFilters();
      if (filters.length) {
          filters.map(f => {
              f.active = false;
              let values = [];
              f.values.map(v => {
                  values.push({ value: v, isChecked: false });
              });
              f.values = values;
          });
          this.adminFilters = filters;
          console.log('adminFilters', this.adminFilters);
      }
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  clearAllFilters() {
    this.modalController.dismiss({
      clearAll: true
    });
  }

  applyFilters() {
    let priceRange = {lower: this.priceRange.lower};
    const parentObj = {type: this.categoryId ? 'brands' : 'categories', ids: []};
    if (this.priceRange.upper === this.priceRangeMax) {
      priceRange['upper'] = 10000000000000;
    } else {
      priceRange['upper'] = this.priceRange.upper;
    }
    for (const d of this.discountFilter) {
      if (d.isChecked) {
        this.discountRange = d.range;
        break;
      }
    }
    for (const r of this.ratingFilter) {
      if (r.isChecked) {
        this.ratingRange = r.range;
        break;
      }
    }
    for (const p of this.parentFilter) {
      if (p.isChecked) {
          // if (this.brandId) {
          //     parentObj.ids.push(p.name)
          // } else {
          //     parentObj.ids.push(p.id);
          // }
          parentObj.ids.push(p.id);
      }
    }

    this.modalController.dismiss({
      parentObj: parentObj,
      priceRange: priceRange,
      discountRange: this.discountRange,
      ratingRange: this.ratingRange,
      adminFilters: this.adminFilters,
      clearAll: false
    });
  }

  toggleFiltersActive(type: string, status: boolean) {
    this.filtersActive[type] = status;
  }

  toggleAdminFiltersActive(i) {
    this.adminFilters[i].active = !this.adminFilters[i].active;
  }

  selectFilters(type: string, i: number) {
    if (type === 'parent') {
      this.parentFilter[i].isChecked = !this.parentFilter[i].isChecked;
    }
  }

  selectAdminFilters(i, j) {
    this.adminFilters[i].values[j].isChecked = !this.adminFilters[i].values[j].isChecked;
  }

  selectRadioFilters(type: string, i: number) {
    if (type === 'discount') {
      for (let index = 0; index < this.discountFilter.length; index++) {
        if (i === index) {
          this.discountFilter[index].isChecked = true;
        } else {
          this.discountFilter[index].isChecked = false;
        }
      }
    }
    if(type === 'rating') {
      for (let index = 0; index < this.ratingFilter.length; index++) {
        if (i === index) {
          this.ratingFilter[index].isChecked = true;
        } else {
          this.ratingFilter[index].isChecked = false;
        }
      }
    }
  }

  async presentAlert(msg: string) {
    const alert = await this.alertController.create({
      message: msg,
      buttons: [`${this.SHARED_LABELS['ok']}`]
    });

    await alert.present();
  }

  disableApplyBtn() {
    let disable = true;
    this.discountFilter.forEach(d => {
      if (d.isChecked) {
        disable = false;
      }
    });
    if(disable) {
      this.ratingFilter.forEach(r => {
        if (r.isChecked) {
          disable = false;
        }
      });
      if(disable) {
        this.parentFilter.forEach(p => {
          if (p.isChecked) {
            disable = false;
          }
        });
        if(disable) {
          if(this.priceRange.lower !== 1 || this.priceRange.upper !== this.priceRangeMax) {
            disable = false;
          }
          if(disable) {
            this.adminFilters.forEach(filter => {
              filter.values.forEach(v => {
                if(v.isChecked) {
                  disable = false; 
                }
              });
            });
          }
        }
      }
    }
    return disable;
  }

  hideFilter(filter: any) {
    if(this.categoryId) {
      if(!filter.hasOwnProperty('categories')) {
        return false;
      }
      if(filter.hasOwnProperty('categories') && filter.categories.includes(this.categoryId)) {
        return false;
      } else {
        return true;
      }
    }
    if(this.brandId) {
      if(!filter.hasOwnProperty('brands')) {
        return false;
      }
      if(filter.hasOwnProperty('brands') && filter.brands.includes(this.brandId)) {
        return false;
      } else {
        return true;
      }
    }
  }

  removeSubscriptions() {
    this.events.unsubscribe('product:publishAllCategoriesForShop');
    this.events.unsubscribe('product:noCategoryAvailable');
    this.events.unsubscribe('brands:publishAllBrandsForUser');
    this.events.unsubscribe('brands:noBrandAvailableForUser');
    this.events.unsubscribe('filters:publishAllActiveFilters');
  }

}
